import { Routes, Route } from "react-router-dom";
import Page404 from "./404";
import Home from "./routes/c/home";
import Record_index from "./routes/c/record";

// CSS
import "./App.css";
import "./general.css";
import Top from "./routes/c/top";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/record" element={<Record_index />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
