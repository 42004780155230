import React from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Carousel,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import { Header } from "../header";

// CSS
import "../../../general.css";
import "./home.css";

const Home = () => {
  // 使用デバイスがモバイルサイズかどうか
  const isSp = UseMediaQuery(mediaQuery.sp);

  return (
    <>
      {/* <Header title="poypoy" /> */}
      {/* <div className="m-3"> */}
      {/* {isSp ? ( //モバイル表示
          <></>
        ) : (
          //デスクトップ表示
          <>
            <Alert variant="warning">
              スマートフォンでのご利用を推奨しております
            </Alert>
          </>
        )} */}
      {/* </div> */}
      <Carousel
        controls={false}
        indicators={true}
        touch={true}
        interval={3000}
        className="home-carousel"
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <Carousel.Item>
            <div
              style={{
                height:
                  "max( calc( min( calc(100vw / 16 * 9) , calc(100svh - 376px)) + 48px ) , calc(100svh - 328px) )",
              }}
            >
              <Image
                src={"/img/ad/ad_" + String(index + 1) + ".png"}
                style={{
                  width: "min( 100vw , calc((100svh - 376px) / 9 * 16))",
                }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="home-footer home-footer-top">
        <p className="home-footer-top_caption">獲得ポイント</p>
        <p className="m-0">
          <span className="home-footer-top_points">1,234</span>
          <span className="home-footer-top_yen">円分</span>
        </p>
        <Image
          src="/img/exchange_btn.png"
          className="home-footer-top_exchange"
        />
        <Image src="/img/gp_l.png" className="home-footer-top_gp-l" />
        <Image src="/img/gp_r.png" className="home-footer-top_gp-r" />
      </div>
      <div className="home-footer home-footer-bottom">
        <Image src="/img/home.png" className="home-footer-bottom_home" />
        <Image src="/img/notice.png" className="home-footer-bottom_notice" />
      </div>
      <Link to="/record">
        <Image
          src="/img/camera_btn.png"
          className="home-footer home-footer-button"
        />
      </Link>
    </>
  );
};

export default Home;
