import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import { HeaderRB } from "../header";
import shutterSound from './Camera-Phone02-1.mp3'
import "./cam.css";

// CSS
import "../../../general.css";
// import WebcamCapture from "./cam";

const SelectPhoto = ({ photo, setSelected, setCaptured }) => {
    return (
        <>
            <HeaderRB />
            <div className='webcam_container'>
                {photo ? <img className='position-absolute start-0 translate-middle-y' style={{ top: "50%" }} src={photo} width="100%" alt="Captured" /> : <p>No photo captured</p>}
                <div className='photoSelectButton position-absolute start-0' onClick={() => { setSelected(false); setCaptured(false); }}>再撮影</div>
                <div className='photoSelectButton position-absolute end-0' onClick={() => setSelected(true)}>写真を使用</div>
                {/* <Button className='shutterButton position-absolute start-50 translate-middle-x' variant='light' onClick={takePhoto}></Button> */}
            </div>

        </>
    );
};


export default SelectPhoto;
