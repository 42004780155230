import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Carousel,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import { Header } from "../header";
import PhotographerHome from "../photographer";
import TrashCollectorHome from "../trashCollector";

// CSS
import "../../../general.css";
import "./top.css";
import Home from "../home";

const Top = () => {
  // 使用デバイスがモバイルサイズかどうか
  const isSp = UseMediaQuery(mediaQuery.sp);
  // スライド位置の取得
  const [index, setIndex] = useState(0);
  //　スライドされた時にスライド位置を格納
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  // ユーザが何を選択したか
  const [userType, setUserType] = useState(sessionStorage.userType);

  // 選択ボタンが押されたら
  const handleSubmit = () => {
    if (index === 0) {
      sessionStorage.userType = "photographer";
      setUserType("photographer");
    } else {
      sessionStorage.userType = "trashCollector";
      setUserType("trashCollector");
    }
  };

  // レンダー
  if (!userType) {
    // 初めて開く場合（タブを閉じてから改めて開いた場合は、新規と見なす）
    return (
      <>
        <div className="smContainer position-absolute top-50 start-50 translate-middle">
          {/* {isSp ? ( //モバイル表示
              <> */}
          <Carousel
            controls={false}
            indicators={true}
            touch={true}
            interval={null}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <div className="top_Carouse">
                <Image
                  className="top_Carouse_img"
                  src="/img/camera_green.png"
                  height="210px"
                />
              </div>
              <Carousel.Caption className="text-dark">
                <h1 className="fs-1">撮り専</h1>
                <p className="mt-4">
                  街中で見つけたゴミを
                  <br />
                  <b className="text_sub fs-5">撮影</b>
                  するだけでポイントGET!
                  <br />
                  <br />
                  <br />
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className="top_Carouse">
                <Image
                  className="top_Carouse_img"
                  src="/img/gomihiroi_green.png"
                  height="210px"
                />
              </div>
              <Carousel.Caption className="text-dark">
                <h1 className="fs-1">拾い専</h1>
                <p className="mt-4">
                  街中で見つけたゴミを
                  <br />
                  <b className="text_sub fs-5">拾う</b>
                  だけでポイントGET!
                  <br />
                  <br />
                  <br />
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>

          <div className="d-grid">
            <Button
              className="sub_button"
              variant="danger"
              size="lg"
              onClick={handleSubmit}
            >
              <b>&emsp;選&ensp;択&emsp;</b>
            </Button>
            <Form>
              <Form.Check
                id="DoNotShowNextTime"
                className="text-start mt-3"
                style={{ display: "flex", justifyContent: "center" }}
                label="　次回以降表示しない"
              />
            </Form>
          </div>
        </div>
      </>
    );
  } else {
    // 既に開いている場合
    return <Home />;
    // if (userType === "trashCollector") {
    //   // 拾い専
    //   return <TrashCollectorHome />;
    // } else {
    //   // 撮り専
    //   return <PhotographerHome />;
    // }
  }
};

export default Top;
