import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from '../../useMediaQuery'
import { slide as Menu } from "react-burger-menu";

// CSS
import "../../general.css";
import "./header.css";


// 通常ヘッダ
const Header = ({ title }) => {
  // ハンバーガーメニューの開閉
  const [isOpen, setOpen] = useState(false);
  // ハンバーガーメニューを閉じる処理
  const closeBurger = () => {
    setOpen(false);
  }
  // ハンバーガーメニューのスタイル
  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '20px',
      top: '20px'
    },
    bmBurgerBars: {
      background: '#fff'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px',
      right: '20px'
    },
    bmCross: {
      background: '#373a47'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#fff',
      padding: '2.5em 1.0em 0',
      fontSize: '1.15em',
    },
    bmMorphShape: {
      fill: '#fff'
    },
    bmItemList: {
      color: '#373a47',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  return (
    <>
      <header>
        <div id='header-burgerMenu_outerContainer'>
          <Menu pageWrapId={"header-burgerMenu_pageWrap"} outerContainerId={"header-burgerMenu_outerContainer"} width={"320px"} styles={styles} isOpen={isOpen} onStateChange={(state) => setOpen(state.isOpen)}>
            <div id="header-burgerMenu_pageWrap">
              テスト
            </div>
          </Menu>
        </div>
        <div className="d-grid h-100" style={{ alignItems: "center" }}>
          <h1 className="text-light m-0">{title}</h1>
        </div>
      </header>
    </>
  );
}

// タイトルと戻るボタンのヘッダ
const HeaderR = ({ title }) => {
  // 前のページに戻る
  let navigate = useNavigate();

  return (
    <>
      <header>
        <div className="position-absolute start-0" style={{ height: "70px", zIndex: 10000 }}>
          <span onClick={() => navigate(-1)}>
            <Image className="position-absolute top-50 start-0 translate-middle-y" style={{ alignItems: "center" }} src="/img/back.png" width="50px" />
          </span>
        </div>
        <div className="d-grid h-100" style={{ alignItems: "center" }}>
          <h1 className="text-light m-0">{title}</h1>
        </div>
      </header >
    </>
  );
}


// 戻るボタンのみ（黒背景）
const HeaderRB = () => {
  // 前のページに戻る
  let navigate = useNavigate();

  return (
    <>
      <div className="position-absolute start-0" style={{ height: "70px", zIndex: 10000 }}>
        <span onClick={() => navigate(-1)}>
          <Image className="position-absolute top-50 start-0 translate-middle-y" style={{ alignItems: "center" }} src="/img/back.png" width="50px" />
        </span>
      </div>
    </>
  );
}


// タイトルと戻るボタンのヘッダ
const HeaderX = ({ title }) => {
  // 前のページに戻る
  let navigate = useNavigate();

  return (
    <>
      <header>
        <div className="position-absolute start-0" style={{ height: "70px", zIndex: 10000 }}>
          <Link to="/">
            <Image className="position-absolute top-50 start-0 translate-middle-y" style={{ alignItems: "center" }} src="/img/close.png" width="50px" />
          </Link>
        </div>
        <div className="d-grid h-100" style={{ alignItems: "center" }}>
          <h1 className="text-light m-0">{title}</h1>
        </div>
      </header >
    </>
  );
}


// タイトルのみヘッダ
const HeaderT = ({ title }) => {
  return (
    <>
      <header>
        <div className="d-grid h-100" style={{ alignItems: "center" }}>
          <h1 className="text-light m-0">{title}</h1>
        </div>
      </header>
    </>
  );
}


export { Header, HeaderR, HeaderRB, HeaderX, HeaderT };
